import React from 'react';
import CVFile from "../../Carlos_Castaneda_CV.pdf";

import {trackEvent} from "../../utils";

const fileName = "Carlos_Castaneda_Aispuro_CV.pdf";

const downloadTxtFile = () => {
    // anchor link
    const element = document.createElement("a");
    element.href = CVFile;
    element.download = fileName
    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    // send event to Google Analytics

    window.dataLayer.push({
      'event': 'download-cv',
      'cv-file-name': fileName
    });
    trackEvent('download', { file: fileName });
}

const DownloadButton = () => {
    return (
        <div
            onClick={downloadTxtFile}
            className="mt-5 relative text-sm font-medium text-white group active:text-[#636365] focus:none mx-auto block w-fit cursor-pointer select-none"
        >
            <span
                className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#636365] group-hover:translate-y-0 group-hover:translate-x-0"
            ></span>

            <span className="relative block px-8 py-3 bg-[#232333] border border-current">
                Download CV
            </span>
        </div>
    );
}

export default DownloadButton;