import React from "react";
import { contactDetails } from "../constants";
import { useSetAnimationPlayed } from "src/hooks/useSetAnimationPlayed";

function Contact() {
    useSetAnimationPlayed();
    const { email, phone } = contactDetails;
    return (
        <div className="flex items-center justify-center flex-col min-h-[50vh] max-width">
            <h1 className="text-center text-2xl md:text-3xl lg:text-6xl text-dark-heading dark:text-light-heading font-semibold md:font-bold">
                Please feel free to contact me via email.
            </h1>
            <div className="text-center text-2xl md:text-4xl lg:text-6xl text-gradient font-semibold md:font-bold pt-5 md:pt-10 md:pb-6">
                <a href={`mailto:${email}`}>{email}</a>
            </div>
            <span className="text-center text-content text-xl font-bold block">or</span>
            <div className="text-center text-3xl md:text-4xl lg:text-6xl text-gradient font-semibold md:font-bold pt-2 md:py-6">
                <a href={`tel:${phone}`}>{phone}</a>
            </div>
        </div>
    );
}

export default Contact;