import React, {
  useLayoutEffect,
  useEffect,
  useRef,
  forwardRef,
  useContext,
} from "react";
import gsap from "gsap";
import DownloadButton from "../components/DownloadButton/DownloadButton";
import logo from "../logo-CC.png";
import { AppContext } from "src/contexts/AppContext";
import "./Home.css";

const Home = () => {
  const animation = useRef<gsap.core.Animation>();
  const { animationPlayed, setAnimationPlayed } = useContext(AppContext);

  type FadeInProps = {
    children: React.ReactNode;
    stagger: number;
    x: number;
  };

  const FadeIn = forwardRef(
    ({ children, stagger = 0, x = -100 }: FadeInProps, ref) => {
      const el = useRef<HTMLSpanElement>(null);

      useLayoutEffect(() => {
        if (!animationPlayed) {
          const ctx = gsap.context(() => {
            animation.current = gsap.from(
              Array.from(el.current?.children || []),
              {
                opacity: 0,
                stagger,
                delay: 0.5,
                duration: 2,
                x,
              }
            );
          });
          setTimeout(() => {
            setAnimationPlayed(true);
          }, 4500);
          return () => ctx.revert();
        }
      }, [stagger, x]);

      useEffect(() => {
        // Forward the animation instance
        if (typeof ref === "function") {
          ref(animation.current);
        } else if (ref) {
          ref.current = animation.current;
        }
      }, [ref]);

      return <span ref={el}>{children}</span>;
    }
  );

  return (
    <div className="flex items-center justify-center min-h-[55vh] select-none">
      <FadeIn stagger={0.8} x={-100} ref={animation}>
        <div className="text-center">
          <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
            Hi!
            <br />
            My Name is
            <br />
          </h1>
        </div>
        <div className="my-5">
          <img src={logo} alt="Carlos Castaneda" />
        </div>
        <DownloadButton />
      </FadeIn>
    </div>
  );
};

export default Home;
