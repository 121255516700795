// Enter all your detials in this file
// Logo images
import logogradient from "../assets/logo.svg";
import logo from "../assets/logo2.svg";
// Profile Image
import profile from "../assets/profile.jpg";
// Tech stack images
import html from "../assets/techstack/html.png";
import css from "../assets/techstack/css.png";
import sass from "../assets/techstack/sass.png";
import js from "../assets/techstack/js.png";
import react from "../assets/techstack/react.png";
import redux from "../assets/techstack/redux.png";
import tailwind from "../assets/techstack/tailwind.png";
import bootstrap from "../assets/techstack/bootstrap.png";
import vscode from "../assets/techstack/vscode.png";
import github from "../assets/techstack/github.png";
import git from "../assets/techstack/git.png";
import npm from "../assets/techstack/npm.png";
import postman from "../assets/techstack/postman.png";
import figma from "../assets/techstack/figma.png";
import nodejs from "../assets/techstack/nodejs.png";

// Porject Images
import BBBImage from "../assets/projects/BBB-screenshot.PNG";
import CreatableImage from "../assets/projects/creatable-screenshot.PNG";
import MacysImage from "../assets/projects/Macys-screenshot.PNG";
import TaylorImage from "../assets/projects/Taylor-screenshot.PNG";

// Logos
export const logos = {
    logogradient: logogradient,
    logo: logo,
};

export const personalDetails = {
    name: "Carlos Castañeda Aispuro",
    tagline: "Full-Stack Developer",
    img: profile,
    about: `As a seasoned web developer with over 7 years of experience, I am well-versed in JavaScript, React.js, Node.js, HTML, CSS, and responsive web development.
    I have a passion for creating clean, modern, and user-friendly websites that deliver a seamless user experience.
    Throughout my career, I have developed a strong understanding of web development best practices and industry standards. I am constantly striving to stay up-to-date with emerging technologies and trends to ensure that I am providing the most efficient and effective solutions to my clients.
    My approach to web development is focused on collaboration and communication. I believe that the key to a successful project is open and transparent communication with my clients, understanding their needs, and delivering solutions that meet and exceed their expectations.
    When I'm not coding, you can find me drumming with my band. I believe that a well-rounded life leads to more creativity and better problem-solving skills in my work.`,
};

export const socialMediaUrl = {
    linkdein: "https://www.linkedin.com/in/carlos-castañeda-aispuro",
    github: "https://www.github.com/karloscastaneda93",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
    facebook: "https://facebook.com/karlozxz"
};

export const workDetails = [
    {
        Position: "Full Stack Developer",
        Company: ` Creatable.io/TVPage, inc.`,
        Location: "Tijuana, B.C, Mexico",
        Type: "Full Time",
        Duration: "November 2016 - Current",
        Description:`<ul class="list-disc list-inside">
                            <li class="mb-2">I was responsible for maintaining and developing new video player widgets utilizing JavaScript, HTML, CSS, Platform API, and Analytics libraries.</li>
                            <li class="mb-2">Migrated and maintained legacy client video websites from JavaScript/jQuery/HTML/CSS implementations to new technologies such as React.js/Redux, WebPack, and SASS, using mobile-first development, SEO, and web accessibility optimization.</li>
                            <li class="mb-2">Implemented 3rd party Analytic integrations on client websites (Adobe Omniture, Google Analytics, etc).</li>
                            <li class="mb-2">Improved and created new website pipelines using CircleCi - YAML.</li>
                            <li class="mb-2">Implemented Unit & E2E testing for several websites using Jest and Puppeteer, CRON jobs for automated deploys, and Slack webhooks for monitoring status.</li>
                            <li class="mb-2">Eliminated website bottlenecked deploys, using AWS S3, and Lambda with the help of CircleCi for seamless/automated workflows.</li>
                            <li class="mb-2">Supported React Native applications and Platform APIs using python and MySQL Database.</li>
                            <li class="mb-2">Supported Platform interface (React.js/TypeScript/styled- components/Material UI).</li>
                            <li class="mb-2">Developed and supported the company website using WordPress.</li>
                        </ul>`
    },
    {
        Position: "Front End Developer",
        Company: `SAINT Tecnologias.`,
        Location: "Tijuana, B.C, Mexico",
        Type: "Full Time",
        Duration: "January 2015 - November 2016",
        Description:`<ul class="list-disc list-inside">
                            <li class="mb-2">
                                Work closely with the team to develop and support SaintBlu/SafeLearning front-end projects using Aurelia.js/HTML/CSS/Bootstrap
                            </li>
                            <li class="mb-2">
                                Help the team create new Interface features such as an Analytics dashboard, password reset modals, add input validations, and support pages.
                            </li>
                            <li class="mb-2">
                                Support company website using WordPress.
                            </li>
                        </ul>`
    },
    {
        Position: "Web Analyst",
        Company: `Sosvia, Inc.`,
        Location: "Tijuana, B.C, Mexico",
        Type: "Full Time",
        Duration: "May 2013 - January 2015",
        Description:`<ul class="list-disc list-inside">
                            <li class="mb-2">
                            Help create Web scraping and data mining process using Heritrix web crawler to get data and update Application DB.
                            </li>
                            <li class="mb-2">
                                Worked on generating scripts to clean and debug scrapped content.
                            </li>
                            <li class="mb-2">
                                Used SVN for control versioning.
                            </li>
                        </ul>`
    },
];

export const eduDetails = [
    {
        Position: "Bachelor's Degree in Computer System Engineer",
        Company: "Centro Universitario de España y Mexico.",
        Location: "Tijuana, B.C, Mexico",
        Type: "Full Time",
        Duration: "Graduated April 2021",
        Description:""
    }
];

// Tech Stack and Tools
export const techStackDetails = {
    html: html,
    css: css,
    js: js,
    react: react,
    redux: redux,
    sass: sass,
    nodejs:nodejs,
    tailwind: tailwind,
    bootstrap: bootstrap,
    vscode: vscode,
    postman: postman,
    npm: npm,
    git: git,
    github: github,
    figma: figma,
};

// Project Details
export const projectDetails = [
    {
        title: "Taylor Guitars - Videos",
        image: TaylorImage,
        description: `Video website showcasing guitars with instructional videos on playing techniques, guitar maintenance, and setup. Offers product reviews, comparisons, and recommendations for guitars and accessories. Provides a forum for guitarists to exchange information and share experiences. A passion project aimed at inspiring and educating guitar enthusiasts of all skill levels`,
        techstack: "React.JS, Redux, Bootstrap",
        previewLink: "https://www.taylorguitars.com/videos/",
        githubLink: "",
        isPrivate: true
    },
    {
        title: "Bed Bath and Beyond - Videos",
        image: BBBImage,
        description: `Video website for bath-related products featuring demonstrations, tips, reviews, and product recommendations. Provides links to purchase products and encourages bath enthusiasts to try new products and techniques in a fun and informative way.`,
        techstack: "HTML/CSS, JavaScript, jQuery",
        previewLink: "https://video.bedbathandbeyond.com/",
        githubLink: "",
        isPrivate: true
    },
    {
        title: "Macy's - Videos",
        image: MacysImage,
        description: `Video website for leading fashion retailer that offers the latest brands in clothing, accessories, jewelry, beauty, shoes, and home products for both men and women.`,
        techstack: "React.JS, Redux, Bootstrap",
        previewLink: "https://www.macys.com/style-crew/",
        githubLink: "https://github.com",
        isPrivate: true
    },
    {
        title: "Creatable.io",
        image: CreatableImage,
        description: `Creatable eCommerce website. The company provides tools and resources to help creators promote their products and reach their target audience through effective marketing strategies.`,
        techstack: "Wordpress",
        previewLink: "https://creatable.io/",
        githubLink: "",
        isPrivate: true
    },
];

// Contact Details
export const contactDetails = {
    email: "karlos.castaneda93@gmail.com",
    phone: "+52 (664) 398 8138",
};