import React, { Dispatch, SetStateAction } from "react";

interface AccordionProps {
  title: string;
  children: string | TrustedHTML;
  index: number;
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number>>;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  index,
  activeIndex,
  setActiveIndex,
}) => {
  const handleSetIndex = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? 0 : index));
  };

  return (
    <>
      <div className="flex w-full justify-between mt-5 rounded">
        <div className="flex">
          <div className="text-content md:text-lg lg:text-xl">{title}</div>
        </div>
        <div
          className="flex items-center justify-center cursor-pointer hover:text-white"
          onClick={() => handleSetIndex(index)}
        >
          {activeIndex === index ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 15l7-7 7 7"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          )}
        </div>
      </div>

      {children && activeIndex === index && (
        <div
          className="p-4 mb-6"
          dangerouslySetInnerHTML={{ __html: children }}
        />
      )}
    </>
  );
};

export default Accordion;
