import React from 'react';
import Stack from '../components/Stack/Stack';
import Project from '../components/Project/Project';
import { projectDetails } from '../constants';
import { useSetAnimationPlayed } from 'src/hooks/useSetAnimationPlayed';

const Projects = () => {
    useSetAnimationPlayed();
    return (
        <div id='projects' className='App-projects min-h-[70vh] px-[2rem]'>
            <Stack />
            <section className='my-5'>
                <div className="flex items-center justify-center text-center text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
                    Projects
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                    </svg>

                </div>
                <div className="flex items-center justify-center text-center text-content py-2 my-5">
                    Some of the Projects I've worked on
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10">
                    {React.Children.toArray(
                        projectDetails.map(
                            ({ title, image, description, techstack, previewLink, githubLink, isPrivate }) => (
                                <Project
                                    title={title}
                                    image={image}
                                    description={description}
                                    techstack={techstack}
                                    previewLink={previewLink}
                                    githubLink={githubLink}
                                    isPrivate={isPrivate}
                                />
                            )
                        )
                    )}
                </div>
            </section>
        </div>
    )
}

export default Projects