/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const HamburgerMenu = ({isOpen}:{isOpen:boolean}) => {

    return (
        <div>
            <button
                type="button"
                className="p-2 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className={`w-6 h-6 ${isOpen ? 'hidden' : 'block'}`}
                    css={css`
                        transition: transform 0.2s ease-in-out;
                        transform-origin: center;
                        &:hover {
                            transform: scale(1.1);
                        }
                    `}
                >
                    <path
                        className="fill-current"
                        d="M3,6h18a1,1,0,0,0,0-2H3A1,1,0,0,0,3,6ZM21,10H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0,6H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                    />
                </svg>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className={`w-6 h-6 ${isOpen ? 'block' : 'hidden'}`}
                    css={css`
                        transition: transform 0.2s ease-in-out;
                        transform-origin: center;
                        &:hover {
                            transform: scale(1.1);
                        }
                    `}
                >
                    <path
                        className="fill-current"
                        d="M18.3,5.71a1,1,0,0,0-1.41,0L12,10.59,7.11,5.7a1,1,0,0,0-1.41,1.41L10.59,12,5.7,16.89a1,1,0,0,0,1.41,1.41L12,13.41l4.89,4.89a1,1,0,0,0,1.41-1.41L13.41,12Z"
                    />
                </svg>
            </button>
        </div>
    );
};

export default HamburgerMenu;
