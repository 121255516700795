import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import logo from '../../carlos-castaneda-website-favicon-white.png';

import './Header.css';

function Header() {
    const [isMobile, setIsMobile] = useState(false);

    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    const path = location?.pathname || '';
    const defaultPage = path === '/' ? 'home' : path.substring(1);
    const [activePage, setActivePage] = useState(defaultPage);

    const toggleClass = (page:string, isHomeIcon = false) => {
        if(!isHomeIcon) 
            setIsOpen(!isOpen);
        if (typeof page === 'string' && !isMobile)
            setActivePage(page);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) { // 768 is a common breakpoint for mobile devices
                setIsMobile(true);
                setActivePage('');
            } else {
                setIsMobile(false);
                setActivePage(defaultPage);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [defaultPage, location]);

    return (
        <header className="container mx-auto md:flex justify-between py-2 max-width items-center select-none">
            <div className="flex justify-center items-center">
                <Link to="/" onClick={() => toggleClass('home', true)}>
                    <img src={logo} alt="logo" />
                </Link>
                <div onClick={() => toggleClass('')} className="cursor-pointer absolute right-10 md:hidden">
                    <HamburgerMenu isOpen={isOpen} />
                </div>
            </div>
            <div className="App-hero-menu font2">
                <nav className={` ${!isOpen ? "hidden" : ""} text-center md:flex justify-between`}>
                    <ul className="dark:text-light-content font-medium md:flex items-center md:space-x-5">
                        <li className={`${activePage === 'home' ? 'active' : ''} pb-1 md:pb-0 my-5`}>
                            <Link to="/" onClick={() => toggleClass('home')}>
                                Home
                            </Link>
                        </li>
                        <li className={`${activePage === 'about' ? 'active' : ''} pb-1 md:pb-0 my-5`}>
                            <Link to="/about" onClick={() => toggleClass('about')}>
                                About
                            </Link>
                        </li>
                        <li className={`${activePage === 'projects' ? 'active' : ''} pb-1 md:pb-0 my-5`}>
                            <Link to="/projects" onClick={() => toggleClass('projects')}>
                                Projects
                            </Link>
                        </li>
                        <li className={`${activePage === 'contact' ? 'active' : ''}`}>
                            <Link to="/contact" onClick={() => toggleClass('contact')}>
                                Contact
                            </Link>
                        </li>

                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;