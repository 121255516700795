import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import About from "./containers/About";
import Contact from "./containers/Contact";
import Home from "./containers/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Projects from "./containers/Projects";
import NotFoundPage from "./containers/NotFoundPage";

import "./App.css";

function App() {
  const location = useLocation();
  return (
    <>
      <Header />
      <main className="container mx-auto max-width section dark:text-light-content pb-24">
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            classNames="fade"
            timeout={500}
          >
            <div>
            <Routes location={location}>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </main>
      <Footer />
    </>
  );
}

export default App;
