import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { techStackDetails } from "../../constants";
import { generateKey } from "../../utils";

const Stack = () => {
    const {
        html,
        css,
        js,
        react,
        redux,
        tailwind,
        nodejs,
        bootstrap,
        sass,
        vscode,
        git,
        github,
        npm,
        postman,
        figma,
    } = techStackDetails;


    const sliderEls = [
        { src: js, title: "javaScript" },
        { src: react, title: "ReactJS" },
        { src: redux, title: "Redux" },
        { src: nodejs, title: "NodeJS" },
        { src: html, title: "HTML5" },
        { src: css, title: "CSS3" },
        { src: tailwind, title: "TailwindCSS" },
        { src: bootstrap, title: "Bootstrap" },
        { src: sass, title: "SASS" },
        { src: vscode, title: "Visual Studio Code" },
        { src: git, title: "Git" },
        { src: github, title: "Github" },
        { src: figma, title: "Figma" },
        { src: npm, title: "NPM" },
        { src: postman, title: "Postman" }
    ];

    const slidersettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        centerMode: true,
        centerPadding: "0 3rem 0",
        autoplay: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <>
            <section className="my-5">
                <h1 className="text-center text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold flex items-center justify-center">
                    Tech Stack
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-10 h-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
                    </svg>
                </h1>
                <p className="text-center text-content py-2 my-5">
                    Technologies and Tools I've been working with recently
                </p>
                <Slider {...slidersettings}>
                    {sliderEls.map((el) => (<div key={generateKey(el.title)}>
                        <div className="flex items-center justify-center flex-col h-[100%]">
                            <img src={el.src} className="px-8 h-[64px] object-contain m-auto" title={el.title} alt="" />
                            <p className="mt-2">{el.title}</p>
                        </div>
                    </div>
                    ))}
                </Slider>
            </section>
        </>
    );
}

export default Stack;