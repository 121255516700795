import React, { ReactNode, createContext, useState } from "react";

type AppContextType = {
  animationPlayed: boolean;
  setAnimationPlayed: (value: boolean) => void;
};

type AppProviderProps = {
  children: ReactNode; // This type includes anything that can be rendered: numbers, strings, elements or an array (or fragment) containing these types.
};

export const AppContext = createContext<AppContextType>({
  animationPlayed: false,
  setAnimationPlayed: () => {},
});

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [animationPlayed, setAnimationPlayed] = useState(false);

  return (
    <AppContext.Provider value={{ animationPlayed, setAnimationPlayed }}>
      {children}
    </AppContext.Provider>
  );
};
